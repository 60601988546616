import React, {useEffect, useState} from "react";
import {QrReader} from 'react-qr-reader';
import './_Scan.scss';
import fetchModel from "../../models/fetchModel";
import TicketInfo from "../../components/TicketInfo/TicketInfo";

const Scan = ({userRole}) => {
  const [error, setError] = useState('No error');
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState('');
  const [ticketInfo, setTicketInfo] = useState({});
  const [showInfo, setShowInfo] = useState(false);
  const ref = React.useRef(null);

  useEffect(() => {
    if (code !== '') {
      console.log('code', code, 'no longer first load');
      closeCamera();
    }

  }, [code]);

  async function closeCamera() {
     const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true,
    });
    stream.getTracks().forEach(function (track) {
        track.stop();
        track.enabled = false;
    });
    console.log('camera closed', ref);
    // ref.current.stopCamera()
  }

  const responseCallback = (response) => {
    if (response) {
      console.log(response);
      if (response.error) {
        console.error('error', response.error);
        setError(response.error);
        return;
      }
      setTicketInfo(response)
      setShowInfo(true);
    } else {
      console.error('error', response.error);
    }
    setIsLoading(false);
  }

  const getTicket = (thisCode) => {
    setIsLoading(true)
    if (userRole === 'staff') {
      fetchModel.postStaff(`/staff/ticket/lookup`, {
        code: thisCode
      }, responseCallback);
    } else if (userRole === 'organiser') {
      fetchModel.post(`/ticket/lookup`, {
        code: thisCode
      }, responseCallback);
    }
  }

  return (
    <main className="Scan">
      <h1>Scan</h1>
      <form
        className="Scan-form"
        onSubmit={e => {
          e.preventDefault();
          console.log('submit');
          setIsLoading(true);
          getTicket(code);
        }}
      >
        <label>Enter code:
          <input
            className="Input-field"
            type="text"
            placeholder="Enter ticket code"
            value={code}
            onChange={e => setCode(e.target.value)}
          />
        </label>
        <button className="Scan-submit Button">Submit</button>
      </form>
      <p className="Error">{error}</p>
      <QrReader
        ref={ref}
        onResult={(result, error) => {
          if (!!result) {
            console.info(result);
            let oldCode = code;
            // check string starts with https://ticketlab.co.uk/ticket/
            if(result?.text.includes('https://ticketlab.co.uk/ticket/')) {
              // get last part of string
              // check if the last part of the string is a valid ticket id
              const newCode = result.text.split('/').pop();
              setCode(newCode);
              console.log(newCode);

              console.log('code', code, 'old code', oldCode, 'new code', newCode, ticketInfo);

              if (newCode !== oldCode && !isLoading) {
                // now we have the code, we can check if it's valid
                getTicket(newCode)
              }
            }
          }

          if (!!error) {
            // console.error(error);
            // setError(error);
          }
        }}
        style={{ width: '100%' }}
        constraints={{ facingMode: 'environment' }}/>
      {ticketInfo.ticket && showInfo &&
        <TicketInfo ticket={ticketInfo.ticket} userRole={userRole} close={() => {
          setShowInfo(false);
          setTicketInfo({});
        }} />
      }
    </main>
  );
}

export default Scan;
