import fetchModel from "../../models/fetchModel";
import Loading from "../Loading/Loading";
import React, {useEffect, useState} from "react";

import './_AdmitToggle.scss';

const AdmitToggle = ({ ticketId, admitted, callback, userRole }) => {
  let [isLoading, setIsLoading] = useState(false);
  let [mutableAdmitted, setMutableAdmitted] = useState(admitted);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setMutableAdmitted(admitted)
  },[admitted])

  function toggleAdmitted(e) {
    setIsLoading(true);
    const newAdmitted = !mutableAdmitted;

    const responseCallback = (response) => {
      if (response) {
        setIsLoading(false);
        if (response.error) {
          setErrorMessage(response.error.message);
          return;
        }

        if (response.admitted === true) {
          setMutableAdmitted(true);
        } else if (response.admitted === false) {
          setMutableAdmitted(false);
        }
        callback(response.admitted);
      }
    }

    let url = '';
    if (userRole === 'staff') {
      url = '/staff/ticket/admission';

      fetchModel.postStaff(
        url,
        {ticketId: ticketId, admitted: newAdmitted},
        responseCallback
      );
    } else {
      url = '/ticket/admission';

      fetchModel.post(
        url,
        {ticketId: ticketId, admitted: newAdmitted},
        responseCallback
      );
    }
  }

  function errorMessages() {
    return isLoading ? null : <div className="AdmitToggle-error">{errorMessage}</div>
  }

  function loadingOrNot() {
    return isLoading ? <Loading /> :
      <>
        <label>
          <input
            className="AdmitToggle-checkbox"
            type="checkbox"
            checked={mutableAdmitted}
            onChange={toggleAdmitted} />
          Admitted
        </label>
        {errorMessages()}
      </>;
  }

  return <div className="AdmitToggle">
    {loadingOrNot()}
  </div>;
};

export default AdmitToggle;
