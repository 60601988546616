import * as React from "react";
import { Link } from "react-router-dom";

import "./NavBar.scss";
import cookieModel from "../../models/cookieModel";

let NavBar = ({routes, isLoggedIn, userRole}) => {
  const logout = function() {
    cookieModel.removeCookie('ticketlab_access');
    cookieModel.removeCookie('ticketlab_staff_access');
    window.location.href = "https://ticketlab.co.uk/login/logout";
  }

  return (
    <nav className="NavBar">
      {routes.map((route, index) => {
        if (route.inNav) {
          if (route.authGuard && isLoggedIn && (route.role === undefined || route.role === userRole)) {
            return (
              <Link className="NavBar-item" key={index} to={route.path}>{route.name}</Link>
            );
          }
          // else if (!route.authGuard && !isLoggedIn) {
          //   return (
          //     <Link className="NavBar-item" key={index} to={route.path}>{route.name}</Link>
          //   );
          // }
        }
        return null;
      })}
      {isLoggedIn &&
        <button className="NavBar-item Button" onClick={logout}>Logout</button>
      }
    </nav>
  );
}

export default NavBar;
