import cookieModel from './cookieModel';

const fetchModel = {
  post: function(url, postBody, callback) {
    let fetchUrl = process.env.REACT_APP_API_URL + url;

    fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookieModel.getCookie('ticketlab_access')
      },
      body: JSON.stringify(postBody)
    })
    .then(r => r.json())
    .then(response => {
      callback(response);
    })
    .catch(e => {
      callback({ error: e });
    });
  },
  postStaff: function(url, postBody, callback) {
    let fetchUrl = process.env.REACT_APP_API_URL + url;

    fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': cookieModel.getCookie('ticketlab_staff_access')
      },
      body: JSON.stringify(postBody)
    })
      .then(r => r.json())
      .then(response => {
        callback(response);
      })
      .catch(e => {
        callback({ error: e });
      });
  }
};

export default fetchModel;
