import {format} from "date-fns";

const dateModel = {
  formatFromDb: (date) => {
    if (date !== "0000-00-00 00:00:00") {
      let splitDate = date.split(/[- :]/);
      date = new Date(Date.UTC(splitDate[0], splitDate[1]-1, splitDate[2], splitDate[3], splitDate[4], splitDate[5]));
      date = format(date, "dd/MM/yyyy HH:mm")
    }
    return date;
  }
}

export default dateModel;
