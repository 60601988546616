import React from "react";
import "./_ButtonGroup.scss"

function ButtonGroup({buttons}) {
  return (
    <div className="ButtonGroup">
      {buttons.map((button, index) => {
        return (
          <button
            key={index}
            className={"Button" + (button.isActive ? " isActive" : " isInactive")}
            onClick={() => button.callback()}>
            {button.text}
          </button>
        )
      })}
    </div>
  )
}

export default ButtonGroup;
