const cookieModel = {
  getCookie: function(cookieName) {
    let cookies = decodeURIComponent(document.cookie);

    if (cookies) {
      cookies = cookies.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while(cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }

        if(cookie.indexOf(cookieName) === 0){
          return cookie.substring(cookieName.length+1, cookie.length);
        }
      }
    }

    return "";
  },
  setCookie: function(cookieName, cookieValue) {
    let expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + 24 * 60 * 60 * 1000);
    let expires = "expires=" + expiryDate.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
  },
  // remove cookie
  removeCookie: function(cookieName) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
};

export default cookieModel;
