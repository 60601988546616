import React, {useState} from "react";

function Filter({filterString, onChange}) {
    const [mutableFilterString, setMutableFilterString] = useState(filterString ?? "");

    function updateFilterString(newValue) {
      setMutableFilterString(newValue);
      onChange(newValue);
    }

    return (
      <input
        className="Filter Input-field"
        style={{marginBottom: 20}}
        type="text"
        placeholder={"Filter by name or code"}
        value={ mutableFilterString }
        data-testid="filterInput"
        onChange={e => updateFilterString(e.target.value)}
      />
    )
}

export default Filter;
