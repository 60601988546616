import React from "react";

import './Login.scss';
import cookieModel from "../../models/cookieModel";
import fetchModel from "../../models/fetchModel";

function Login (props) {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loginType, setLoginType] = React.useState('organiser');

  // get props
  const { setIsLoggedIn, setUserRole } = props;

  // error if setIsLoggedIn or setUserRole is not passed
  if (!setIsLoggedIn || !setUserRole) {
    throw new Error('setIsLoggedIn and setUserRole are required in props');
  }

  const logIn = (accessToken) => {
    cookieModel.setCookie('ticketlab_access', accessToken);
    setUserRole('organiser');
    setIsLoggedIn(true);
  }

  const staffLogIn = (accessToken) => {
    cookieModel.setCookie('ticketlab_staff_access', accessToken);
    setUserRole('staff');
    setIsLoggedIn(true);
  }

  const loginAction = (e) => {
    e.preventDefault();

    const url = `/login`;

    fetchModel.post(url, {
      username: email,
      email: email,
      password: password,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET
    }, (response) => {
      if (response.access_token) {
        logIn(response.access_token);
      } else if (response.error) {
        setErrorMessage(response.error.message);
      } else {
        if ((response.status === 'error' || response.code >= 400) && response.message) {
          setErrorMessage(response.message);
        } else {
          setErrorMessage('An error occurred');
        }
      }
    });
  }

  const loginStaffAction = (e) => {
    e.preventDefault();

    const url = `/login/staff`;

    fetchModel.post(url, {
      name: email,
      pin: password
    }, (response) => {
      if (response.token) {
        staffLogIn(response.token);
      } else {
        if ((response.status === 'error' || response.code >= 400) && response.message) {
          setErrorMessage(response.message);
        } else {
          setErrorMessage('An error occurred');
        }
      }
    });
  }

  function loginForm() {
    if (loginType === 'organiser') {
      return (
        <form className="Form" onSubmit={loginAction}>
          <label
            className="Input">
            Email:
            <input
              className="Input-field"
              type="text"
              name="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </label>
          <label
            className="Input">
            Password:
            <input
              className="Input-field"
              type="password"
              name="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </label>
          <input
            className="Button"
            type="submit"
            value="Log in"
            onClick={loginAction}
            data-testid="loginButton"
          />
          {errorMessage ? <p className="Error">{errorMessage}</p> : null}
        </form>
      );
    } else {
      return (
        <form className="Form" onSubmit={loginAction}>
          <label
            className="Input">
            Name:
            <input
              className="Input-field"
              type="text"
              name="name"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </label>
          <label
            className="Input">
            Pin:
            <input
              className="Input-field"
              type="number"
              name="pin"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </label>
          <input
            className="Button"
            type="submit"
            value="Log in"
            onClick={loginStaffAction}
          />
          {errorMessage ? <p className="Error">{errorMessage}</p> : null}
        </form>
      );
    }
  }

  return (
    <div className="Login">
      <main>
        <h1 className="Title">Login</h1>
        <div className="ButtonGroup">
          <button
            className={"Button" + (loginType === 'organiser' ? ' isActive' : ' isInactive')}
            onClick={() => setLoginType('organiser')}
          >
            Organiser
          </button>
          <button
            className={"Button" + (loginType === 'staff' ? ' isActive' : ' isInactive')}
            onClick={() => setLoginType('staff')}
          >
            Staff
          </button>
        </div>
        {loginForm()}
      </main>
    </div>
  );
}

export default Login;
