import * as React from "react";
import "./_Loading.scss";

class Loading extends React.Component {
  render() {
    return (
      <svg
        data-testid="loading"
        className="Loading"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="34 34 34 34">
        <circle
          cx="62"
          cy="50"
          fill="#d6e2e4"
          r="4">
          <animate
            attributeName="r"
            values="2.4;2.4;4;2.4;2.4"
            times="0;0.1;0.2;0.3;1"
            dur="1s"
            repeatCount="indefinite"
            begin="-0.875s">
          </animate>
          <animate
            attributeName="fill"
            values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4"
            repeatCount="indefinite"
            times="0;0.1;0.2;0.3;1"
            dur="1s"
            begin="-0.875s">
          </animate>
        </circle>
        <circle
          cx="58.48528137423857"
          cy="58.48528137423857"
          fill="#d6e2e4" r="4">
          <animate
            attributeName="r"
            values="2.4;2.4;4;2.4;2.4"
            times="0;0.1;0.2;0.3;1"
            dur="1s"
            repeatCount="indefinite"
            begin="-0.75s">
          </animate>
          <animate attributeName="fill" values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
        </circle><circle cx="50" cy="62" fill="#d6e2e4" r="4">
          <animate attributeName="r" values="2.4;2.4;4;2.4;2.4" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.625s"></animate>
          <animate attributeName="fill" values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.625s"></animate>
        </circle><circle cx="41.51471862576143" cy="58.48528137423857" fill="#d6e2e4" r="4">
          <animate attributeName="r" values="2.4;2.4;4;2.4;2.4" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
          <animate attributeName="fill" values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
        </circle><circle cx="38" cy="50" fill="#d6e2e4" r="4">
          <animate attributeName="r" values="2.4;2.4;4;2.4;2.4" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.375s"></animate>
          <animate attributeName="fill" values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.375s"></animate>
        </circle><circle cx="41.51471862576143" cy="41.51471862576143" fill="#d6e2e4" r="4">
          <animate attributeName="r" values="2.4;2.4;4;2.4;2.4" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
          <animate attributeName="fill" values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
        </circle><circle cx="50" cy="38" fill="#d6e2e4" r="4">
          <animate attributeName="r" values="2.4;2.4;4;2.4;2.4" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.125s"></animate>
          <animate attributeName="fill" values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.125s"></animate>
        </circle><circle cx="58.48528137423857" cy="41.51471862576143" fill="#d6e2e4" r="4">
          <animate attributeName="r" values="2.4;2.4;4;2.4;2.4" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
          <animate attributeName="fill" values="#d6e2e4;#d6e2e4;#2c98b1;#d6e2e4;#d6e2e4" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
        </circle>
      </svg>
    );
  }
}

export default Loading;
