import React, {useEffect, useState} from "react";
import fetchModel from "../../models/fetchModel";
import Loading from "../../components/Loading/Loading";

function AddStaff({onChange}) {
  let [error, setError] = useState(null);
  let [isLoading, setIsLoading] = useState(false);

  // Add staff member
  const addStaff = function(e) {
    setIsLoading(true)
    e.preventDefault();

    const url = "/staff/add";
    const postBody = {
      name: document.getElementById("name").value,
      pin: document.getElementById("pin").value,
      eventId: 0
    };

    fetchModel.post(url, postBody, (response) => {
      setIsLoading(false);

      if (response) {
        if ((response.status === 'error' || response.code >= 400) && response.message) {
          setError(response.message);
          return;
        }

        setError(null);
        onChange();
      } else {
        //
      }
    })
  }

  return (
    <div className="AddStaff" style={{maxWidth: 400, margin: "auto"}}>
      <h2>Add staff member</h2>
      <form
        className="AddStaff-form"
        onSubmit={addStaff}
      >
        <label className="Input">
          Name
          <input className="Input-field" required type="text" name="name" id="name" />
        </label>

        <label className="Input">
          Pin
          <input className="Input-field" required type="number" name="pin" id="pin" />
        </label>

        {/*<label>*/}
        {/*  Event*/}
        {/*  <select name="event" id="event">*/}
        {/*    <option value="0">All events</option>*/}
        {/*    <option value="1">Event 1</option>*/}
        {/*    <option value="2">Event 2</option>*/}
        {/*  </select>*/}
        {/*</label>*/}

        {error ? <p className="Error">{error}</p> : null}
        {
          isLoading ? (
            <div style={{width: 48, margin: "auto"}}><Loading /></div>
          ) : (
            <button
              className="Button"
              type="submit">Add Staff Member</button>
          )
        }
      </form>
    </div>
  );
}

let Staff = function () {
  let [staff, setStaff] = useState([]);
  let [showAddStaff, setShowAddStaff] = useState(false);
  let [isLoading, setIsLoading] = useState(false);

  function getStaff() {
    setIsLoading(true);
    setShowAddStaff(false);
    const url = "/staff";
    const postBody = {};

    fetchModel.post(url, postBody, (response) => {
      if (response) {
        setStaff(response.staff);
        setIsLoading(false);
      } else {
        //
      }
    })
  }

  useEffect(() => {
    getStaff();
  }, []);

  function showAddStaffPlease(show) {
    return () => {
      setShowAddStaff(show);
    }
  }

  return (
    <main className="Staff">
      <h1>Staff</h1>
      {
        showAddStaff ? (
          <AddStaff onChange={getStaff} />
        ) : (
          <button
            className="Button"
            onClick={showAddStaffPlease(true)}>Add</button>
        )
      }

      {isLoading ? (
        <div className="Staff-loading" style={{maxWidth:100, margin: "auto"}}>
          <Loading />
        </div>
      ) : (
        <div className="Staff-list">
          {staff.map((staff, index) => {
            return <div key={index} className="Staff-item">
              <h2>{staff.name}</h2>
              <p>Staff pin: ******</p>
              {staff.event_id === 0 ? (<p>All events</p>) : (<p>Event: {staff.event_id}</p>)}
            </div>
          })}
        </div>
      )}

    </main>
  );
}

export default Staff;
