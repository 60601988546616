import {Route, Routes} from 'react-router-dom';
import Allocations from './pages/Allocations/Allocations.js';
import Staff from './pages/Staff/Staff.js';
import Scan from './pages/Scan/Scan.js';
import Login from './pages/Login/Login.js';
import NavBar from './components/NavBar/NavBar.js';
import React, {useState} from "react";

import logo from './ticketlab.svg';
import './App.scss';
import cookieModel from "./models/cookieModel";

const App = function () {
  const staffAccessToken = cookieModel.getCookie('ticketlab_staff_access');
  const accessToken = cookieModel.getCookie('ticketlab_access');
  let [isLoggedIn, setIsLoggedIn] = useState(staffAccessToken || accessToken);
  let [userRole, setUserRole] = useState(accessToken ? 'organiser' : 'staff');

  const loginElement = <Login setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} />;

  let routeArray = [
    {
      path: '/',
      element: <Allocations userRole={userRole} />,
      authGuard: true,
      inNav: false
    },
    {
      path: '/login',
      element: loginElement,
      authGuard: false,
      inNav: false
    },
    {
      path: '/allocations',
      element: <Allocations userRole={userRole} />,
      name: 'Allocations',
      authGuard: true,
      inNav: true
    },
    {
      path: '/allocations/:id',
      element: <Allocations userRole={userRole} />,
      name: 'Allocations/Event',
      authGuard: true,
      inNav: false
    },
    {
      path: '/staff',
      element: <Staff />,
      name: 'Staff',
      authGuard: true,
      role: 'organiser',
      inNav: true
    },
    {
      path: '/scan',
      element: <Scan userRole={userRole} />,
      name: 'Scan',
      authGuard: true,
      inNav: true
    }
  ];

  const getRoutes = () => {
    return (
      <Routes>
        {routeArray.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={route.authGuard && !isLoggedIn ? loginElement : route.element}
            />
          )
        })}
      </Routes>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Gofamer"/>
        <NavBar routes={routeArray} isLoggedIn={isLoggedIn} userRole={userRole} ></NavBar>
      </header>
      <div className="App-body">
        {getRoutes()}
      </div>
    </div>
  );
}

export default App;
